<!--业绩页面-->
<template>
    <div class="body">
        <div class="fix-top">
            <time-range-selector v-on:refreshTime="refreshTime"></time-range-selector>
            <!--业绩总览-->
            <div class="data-wrapper">
                <div class="data-line-wrapper">
                    <td class="data-item-wrapper">
                        <span>订单数</span>
                        <span>{{orderCount}}笔</span>
                    </td>
                    <td class="data-item-wrapper">
                        <span>购买人数</span>
                        <span>{{customerCount}}人</span>
                    </td>
                    <td class="data-item-wrapper">
                        <span>退款数</span>
                        <span>{{refundCount}}</span>
                    </td>
                    <td class="data-item-wrapper">
                        <span>商品</span>
                        <span>{{productCount}}种</span>
                    </td>
                </div>
                <div class="data-line-wrapper">
                    <td class="data-item-wrapper">
                        <span>销售额</span>
                        <span>{{computedAllMoney}}元</span>
                    </td>
                    <td class="data-item-wrapper">
                        <span>退款金额</span>
                        <span>{{moneyFen2Yuan(refundMoney)}}</span>
                    </td>
                    <td class="data-item-wrapper">
                        <span>
                            业绩
                            <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/achievementQuestion.png" class="question" @click="viewQuestion">
                        </span>
                        <span>{{moneyFen2Yuan(saleMoney - refundMoney)}}</span>
                    </td>
                    <td class="data-item-wrapper">
                        <span>预估奖励</span>
                        <span>{{moneyFen2Yuan(predictReward)}}元</span>
                    </td>
                </div>
            </div>
        </div>
        <div style="width: 100%;height: 11rem;"></div>
        <!--单品业绩报表-->
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div v-for="(item, index) in listLoadingList" :key="index">
                <div class="product-wrapper">
                    <div class="product-info-wrapper">
                        <img class="product-info-img-wrapper" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item.trademark">
                        <div class="product-info-text-wrapper">
                            <div class="product-info-text-container">
                                <div class="product-info-text">{{item.productInfo.name}}</div>
                            </div>
                            <div class="product-info-money-wrapper">
                                <div>￥{{moneyFen2Yuan(item.productInfo.mallprice)}}</div>
                                <div class="product-info-money-earn">
                                    <div>赚</div>
                                    <div>{{moneyFen2Yuan(item.productInfo.c_level1_money)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="product-achievement-wrapper" >
                        <div class="product-achievement-info-wrapper">
                            <div>销售额</div>
                            <div>{{moneyFen2Yuan(item.achievementMoney)}}</div>
                        </div>
                        <div class="line"></div>
                        <div class="product-achievement-info-wrapper">
                            <div>订单数</div>
                            <div>{{item.achievementCount}}</div>
                        </div>
                        <div class="line"></div>
                        <div class="product-achievement-info-wrapper">
                            <div>退款</div>
                            <div>{{moneyFen2Yuan(item.refundMoney)}}元</div>
                        </div>
                        <div class="line"></div>
                        <div class="product-achievement-info-wrapper">
                            <div>业绩</div>
                            <div>{{moneyFen2Yuan(item.achievementMoney - item.refundMoney)}}元</div>
                        </div>
                        <div class="line"></div>
                        <div class="product-achievement-info-wrapper">
                            <div>预估奖励</div>
                            <div>{{moneyFen2Yuan(item.predictReward)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <van-overlay :show="showQuestion" @click="showQuestion = false">
            <div class="question-wrapper">
                <span>补充说明</span>
                <span>1. 销售额 = 已核销订单金额 + 未核销核销订单金额 + 退款金额</span>
                <span>2. 业绩 = 销售额 - 退款金额</span>
                <span>3. 预估奖励 = 未核销订单佣金 + 已核销订单佣金</span>
                <span>4. 总览只对直卖数据进行统计</span>
                <span>5. 搜索时间段内退款金额大于销售额，业绩可能为负</span>
            </div>
        </van-overlay>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import TimeRangeSelector from '../../components/common/TimeRangeSelector'
  import { globalConfig, listLoading } from '../../utils/mixin'
  import { moneyFen2Yuan } from '../../utils/tools'
  import FloatNav from '../../components/common/FloatNav'
  export default {
    name: 'Achievement',
    components: { FloatNav, TimeRangeSelector },
    data () {
      return {
        showQuestion: false,
        saleMoney: 0,
        orderCount: 0,
        customerCount: 0,
        refundCount: 0,
        refundMoney: 0,
        productCount: 0,
        predictReward: 0
      }
    },
    mixins: [globalConfig, listLoading],
    methods: {
      // 日期动作
      refreshTime: function (start, end) {
        this.listLoadingInit(this.apiUrl + 'mall/distributor/getProductAchievementListV2', 'list', 1, {
          dateStart: start,
          dateEnd: end
        })
        this.axios.post(this.apiUrl + 'mall/distributor/getDistributorReport', {
          dateStart: start,
          dateEnd: end
        }).then((response) => {
          const data = response.data
          console.log(data)
          const result = data.data
          this.saleMoney = result.saleMoney
          this.orderCount = result.orderCount
          this.customerCount = result.customerCount
          this.refundCount = result.refundCount
          this.refundMoney = result.refundMoney
          this.productCount = result.productCount
          this.predictReward = result.predictReward
        })
      },
      viewQuestion: function () {
        this.showQuestion = true
      },
      moneyFen2Yuan: function (money) {
        return moneyFen2Yuan(money)
      }
    },
    computed: {
      computedAllMoney: function () {
        return moneyFen2Yuan(this.saleMoney)
      }
    },
    created () {
    },
    mounted () {
      // this.listLoadingInit(this.apiUrl + 'mall/distributor/getProductAchievementList', 'list', 1)
    }
  }
</script>

<style scoped>
    .body{
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        background: #f2f2f2;
    }
    .fix-top {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
    }
    .question {
        width: 1rem;
        height: 1rem;
        /*position: absolute;*/
        /*right: 1rem;*/
        /*top: 0.5rem;*/
    }
    .data-wrapper {
        background-color: #ffffff;
        width: 100%;
        border-top: 0.1rem solid #eeeeee;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        overflow: hidden;
        /*position: relative;*/
    }
    .data-line-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row;
    }
    .data-item-wrapper {
        width: 33.3%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
        border-right: 0.1rem solid #eeeeee;
        border-bottom: 0.1rem solid #eeeeee;
    }
    .data-item-wrapper > span:first-child {
        color: #bfbfbf;
        font-size: 0.9rem;
    }
    .data-item-wrapper > span:nth-child(2) {
        font-size: 0.9rem;
        color: #585858;
        margin-top: 0.3rem;
    }
    /*.overview-wrapper{*/
    /*    width: 92vw;*/
    /*    display: flex;*/
    /*    justify-content: space-between;*/
    /*    align-items: center;*/
    /*    background: #fff;*/
    /*    padding: 0.5rem 0;*/
    /*    margin: 5rem 4vw 1rem;*/
    /*    border-radius: 3vw;*/
    /*    font-size: 3.6vw;*/
    /*    color: #999;*/
    /*    box-sizing: border-box;*/
    /*}*/
    .overview-data-wrapper div{
        width: 30vw;
        text-align: center;
        padding: 1vw 0;
    }
    .overview-data-wrapper div:nth-of-type(2){
        color: #fe4845;
        font-size: 3.2vw;
        font-weight: 600;
    }
    .line {
        width: 1px;
        height: 8vw;
        background: #e0e0e0;
    }
    .product-wrapper{
        width: 95vw;
        border-radius: 3vw;
        background: #fff;
        margin: 1rem auto 0.5rem;
    }
    .product-info-wrapper{
        display: flex;
        padding: 4vw 0;
        border-bottom: 1px solid #e0e0e0;
    }
    .product-info-img-wrapper{
        width: 20vw;
        height: 20vw;
        margin: 0 3vw;
        border-radius: 2vw;
    }
    .product-info-text-wrapper{
        width: 60vw;;
    }
    .product-info-text-container {
        height: 15vw;
    }
    .product-info-text{
        font-size: 3.6vw;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .product-info-money-wrapper{
        display: flex;
        justify-content: space-between;
        font-size: 3.2vw;
    }
    .product-info-money-earn{
        display: flex;
        height: 5vw;
        background-color: #FAF1F1;
        border-radius: 5vw;
        margin-left: 3.5vw;
    }
    .product-info-money-earn div:nth-of-type(1){
        width: 5vw;
        height: 5vw;
        border-radius: 5vw;
        background-color: #FF504C;
        font-size: 3vw;
        text-align: center;
        line-height: 5vw;
        color: #FFFFff;
    }
    .product-info-money-earn div:nth-of-type(2){
        display: flex;
        align-items: center;
        font-size: 0.7rem;
        padding: 0 4vw 0 2vw;
        color: #FF504C;
    }
    .product-achievement-wrapper{
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #fff;
        font-size: 3.2vw;
        border-radius: 3vw;
        color: #333;
    }
    .product-achievement-info-wrapper{
        text-align: center;
        padding: 1.5vw 0;
    }
    .product-achievement-info-wrapper div{
        padding: 0.5vw 0;
    }
    .product-achievement-info-wrapper div:nth-of-type(2){
        color: #333;
        font-size: 3vw;
        font-weight: 600;
    }
    .question-wrapper {
        background-color: #ffffff;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        padding: 1rem;
        color: #3f3f3f;
    }
    .question-wrapper span {
        margin-top: 0.5rem;
    }
</style>
